import React, { useState } from 'react';
//import Web3 from 'web3';

import logo from "../images/logo.png";
import nephele from "../images/nephele.png";
import db3 from "../SWAP3.json";

const Card = () => {
    const [account, setAccount] = useState(null);
    const [nephTokens, setNephTokens] = useState(0);
    // eslint-disable-next-line no-unused-vars
    const [sinTokens, setSinTokens] = useState(0);
    const [metaMaskInstalled, setMetaMaskInstalled] = useState(true);
    const nephCoins = parseFloat(nephTokens);

    const cleanNumericString = (value) => {
        // Supprimer les espaces de la chaîne numérique pour assurer une conversion correcte
        return value.replace(/\s/g, '');
    };

    const connectMetaMask = async () => {
        if (window.ethereum) {
            try {
                const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                const userAddress = accounts[0]?.toLowerCase(); // Utilisation de ?. pour éviter l'erreur si accounts[0] est null ou undefined
                setAccount(userAddress);

                if (!userAddress) {
                    console.error('User address not available.');
                    return;
                }

                // Recherche de l'utilisateur uniquement dans la base de données db3
                const user = db3.find(u => (u["NEPH Address"]?.toLowerCase() === userAddress) || (u["SIN Address Customized"]?.toLowerCase() === userAddress));

                if (user) {
                    // Convertir et ajouter les NEPH Tokens
                    const totalNephTokens = parseFloat(cleanNumericString(user["Amount NEPH Coins"]).replace(',', '.')) || 0;
                    setNephTokens(totalNephTokens);
                    setSinTokens(parseFloat(user["Amount SIN Coins"]?.replace(',', '.')) || 0);
                } else {
                    // Réinitialiser les valeurs si l'utilisateur n'est pas trouvé
                    setNephTokens(0);
                    setSinTokens(0);
                }

            } catch (error) {
                console.error('User rejected the request or an error occurred.');
            }
        } else {
            setMetaMaskInstalled(false);
            console.error('MetaMask is not installed.');
        }
    };

    return (
        <div>
            <div className='space'>
                <div className='card'>
                    <div className='bg'>
                        <img className='img2' src={logo} alt='nephelelogo' />
                        <img className='img' src={nephele} alt="nepheletext" />
                        {!metaMaskInstalled && (
                            <p className='error1' style={{ color: 'red' }}>MetaMask is not installed. Please install MetaMask and try again.</p>
                        )}
                        {account ? (
                            <div id="userDetails">
                                <div className='results1-container'>
                                    <div className='results1'>
                                        <div className='space1'>
                                            <div className='space'><strong className='strong'>Address : </strong></div>
                                            <span className="account">{account}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='results-container'>
                                    <div className='results'><strong>NEPH Coins : </strong> <span id="nephTokens">{nephCoins}</span></div>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <button className='btn' onClick={connectMetaMask}>Connect MetaMask</button>
                                <p className='advise'>Take a look at your NEPH tokens !</p>
                                <p className='advise2'>You need to have the Metamask extension</p>
                            </div>
                        )}
                    </div>
                    <div className='blob'></div>
                </div>
            </div>
        </div>
    );
};

export default Card;
